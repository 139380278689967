import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import Spacer from '../components/atoms/utility/Spacer';
import BusinessApplicant from '../components/organisms/BusinessApplicant';

export default function ArtprizePage({
  data: {
    heroImg,
    mobileHeroImg,
    panelOneImg,
    panelThreeImg,
    panelFourImg,
  },
}) {
  return (
    <BaseLayout
      heroContent={ArtprizeHeroContent}
      mobileHeroImg={mobileHeroImg}
      heroImg={heroImg}
      heroAlt="Showcasing our products."
    >
      <SEO
        title="ArtPrize | flockx Local Artist Community Boost"
        keywords={[
          'small business empowerment',
          'enterprise marketing',
          'local digital marketing',
          'business solutions',
          'product showcase',
          'community growth',
          'innovative technology',
          'success unlock',
          'customer retention',
        ]}
        description={
          'ArtPrize Activity App - Unlock your business potential with flockx.io. Drive more customers to events & specials, get detailed foot traffic reports, and convert off-peak hours to prime time.'
        }
      />

      <div>
        <Spacer sizeY={1} className="block xl:block" />
        <Spacer className="block sm:hidden" />


        <div className="flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 order-2 lg:order-1 max-w-[700px] mx-auto">
            <GatsbyImage
              image={getImage(panelOneImg)}
              alt={''}
              className="rounded-xl"
            />
          </div>
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              Transform Off-Peak to{' '}
              <span className="text-default">Prime Time</span>
            </h3>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-3xl">
              Convert least popular times into customer-favorite hours by
              advertising your activity to your local community.
            </p>
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />

        <div className="lg:grid gap-10 lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              <span className="text-secondary-orange">Promote</span> your
              Events, Specials and Activities
            </h3>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-3xl mb-8 lg:mb-12">
              Join a local network of digital billboards and displays around
              your city. Delivering a 10x more cost-effective impact.
            </p>
          </div>
          <div className="col-span-6 max-w-[700px] mx-auto">
            <GatsbyImage
              image={getImage(panelThreeImg)}
              alt={''}
              className="rounded-xl"
            />
          </div>
        </div>

        <Spacer className="block sm:hidden" />
        <Spacer sizeY={2} />

        <div className="flex flex-col lg:grid lg:gap-24 grid-cols-12 justify-center items-center">
          <div className="col-span-6 order-2 lg:order-1 max-w-[700px] mx-auto">
            <GatsbyImage
              image={getImage(panelFourImg)}
              alt={''}
              className="rounded-xl"
            />
          </div>
          <div className="col-span-6 text-center lg:text-left mb-20 lg:mb-0 px-4 order-1 lg:order-2 max-w-2xl mx-auto">
            <h3 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-light-grey !leading-[1.2] mb-5">
              Kick-start a Campaign by{' '}
              <span className="text-highlight">Sharing a Link</span>
            </h3>
            <p className="text-lg sm:text-2xl md:text-3xl lg:text-3xl">
              Launch pinpoint campaigns effortlessly by sharing a link to your
              business. No complexity, just simplicity.
            </p>
          </div>
        </div>
        <Spacer sizeY={2} className="block xl:block" />
        <Spacer className="block sm:hidden" />
        <div className="mx-auto w-full flex items-center justify-center">
          <BusinessApplicant />
        </div>
        <Spacer className="block sm:hidden" />
        <Spacer />
      </div>
    </BaseLayout>
  );
}

function ArtprizeHeroContent() {
  return (
    <>
      <div className="-mt-[30%] sm:mt-[5%] lg:mt-0">
        <div className="text-center sm:text-left sm:mt-6 lg:mt-0">
          <div className="mb-10 md:mb-0">
            <h1
              className={
                'text-5xl lg:text-6xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[77px] font-light text-white mb-2 sm:mb-4 md:max-w-xl lg:max-w-3xl xl:max-w-4xl'
              }
            >
              <span className="block font-bold">Welcome, Venues.</span>
              <span className="block">Mobile App Partner of ArtPrize 2023</span>
            </h1>

            <h2 className="block sm:hidden lg:block text-lg sm:text-xl md:text-2xl xl:text-[32px] md:leading-[1.3] lg:leading-[1.6] xl:leading-[48px] mb-4 md:mb-4 lg:mb-8 text-white mx-auto font-light max-w-[240px] sm:max-w-sm lg:max-w-3xl xl:max-w-xl sm:mx-0">
              Increase the impact of ArtPrize and the potential of your business
              by driving more customers to weekly events & specials.
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ArtprizePageQuery {
    heroImg: file(name: { eq: "business-hero" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mobileHeroImg: file(name: { eq: "business-hero-mobile" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelOneImg: file(name: { eq: "transform-business" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelThreeImg: file(name: { eq: "map-promo" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    panelFourImg: file(name: { eq: "how-to" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
